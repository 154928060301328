// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import BarcodesScanner from "../../blocks/BarcodesScanner/src/BarcodesScanner";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import MerchantDashboard2 from "../../blocks/MerchantDashboard2/src/MerchantDashboard2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import PosIntegrationBillingInvoicingSystem2 from "../../blocks/PosIntegrationBillingInvoicingSystem2/src/PosIntegrationBillingInvoicingSystem2";
import Gallery from "../../blocks/Gallery/src/Gallery";
import ExpenseTracking from "../../blocks/ExpenseTracking/src/ExpenseTracking";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Crm3rdPartyIntegration from "../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import VideoCall5 from "../../blocks/VideoCall5/src/VideoCall5";
import WhatsappIntegration16 from "../../blocks/WhatsappIntegration16/src/WhatsappIntegration16";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import DetailedDownloadHistory2 from "../../blocks/DetailedDownloadHistory2/src/DetailedDownloadHistory2";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import ShippingAddressValidation from "../../blocks/ShippingAddressValidation/src/ShippingAddressValidation";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import ZipcodesSearch from "../../blocks/ZipcodesSearch/src/ZipcodesSearch";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import BroadcastMessage from "../../blocks/BroadcastMessage/src/BroadcastMessage";
import ProductRecommendationEngine from "../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine";
import PayrollIntegration2 from "../../blocks/PayrollIntegration2/src/PayrollIntegration2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
BarcodesScanner:{
 component:BarcodesScanner,
path:"/BarcodesScanner"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
MerchantDashboard2:{
 component:MerchantDashboard2,
path:"/MerchantDashboard2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Maps:{
 component:Maps,
path:"/Maps"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
PosIntegrationBillingInvoicingSystem2:{
 component:PosIntegrationBillingInvoicingSystem2,
path:"/PosIntegrationBillingInvoicingSystem2"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
ExpenseTracking:{
 component:ExpenseTracking,
path:"/ExpenseTracking"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Crm3rdPartyIntegration:{
 component:Crm3rdPartyIntegration,
path:"/Crm3rdPartyIntegration"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
VideoCall5:{
 component:VideoCall5,
path:"/VideoCall5"},
WhatsappIntegration16:{
 component:WhatsappIntegration16,
path:"/WhatsappIntegration16"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
DetailedDownloadHistory2:{
 component:DetailedDownloadHistory2,
path:"/DetailedDownloadHistory2"},
TermsAndConditions3:{
 component:TermsAndConditions3,
path:"/TermsAndConditions3"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
ShippingAddressValidation:{
 component:ShippingAddressValidation,
path:"/ShippingAddressValidation"},
MatchAlgorithm:{
 component:MatchAlgorithm,
path:"/MatchAlgorithm"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
ZipcodesSearch:{
 component:ZipcodesSearch,
path:"/ZipcodesSearch"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
BroadcastMessage:{
 component:BroadcastMessage,
path:"/BroadcastMessage"},
ProductRecommendationEngine:{
 component:ProductRecommendationEngine,
path:"/ProductRecommendationEngine"},
PayrollIntegration2:{
 component:PayrollIntegration2,
path:"/PayrollIntegration2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},

  Home: {
component:AdvancedSearch,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
